/*
 * Route Context - branding override
 * Routes can be added or removed here. Additionally, the context can be changed
 * depending on the client requirements.
 */
export default {
    '/ui': 'main_account',
    '/ui/workspace': 'main_account',
    '/ui/PAYMENTS/managePayments': 'transaction',
    '/ui/ADMINSTRATION/viewUsers': 'change_details',
    '/ui/PAYMENTS/manageTransfers': 'transaction',
    '/ui/PAYMENTS/manageTemplateGroups': 'transaction',
    '/ui/PMTS/insert/templates/corp/TEMP_LIST_VIEW': 'add_payee',
    '/ui/PMTS/import/templates/corp/TEMP_LIST_VIEW': 'add_payee',
    '/ui/PMTS/insert/payments/corp/PAY_LIST_VIEW': 'transaction',
    '/ui/PAYMENTS/addPaymentFromTemplate': 'transaction',
    '/ui/PAYMENTS/addTransfer/SINGLE': 'transaction',
    '/ui/PAYMENTS/addTransfer/ONE2ONE': 'transaction',
    '/ui/PAYMENTS/addTransfer/ONE2MANY': 'transaction',
    '/ui/PAYMENTS/addTransferTemplate/SINGLE': 'transaction',
    '/ui/PAYMENTS/addTransferTemplate/ONE2ONE': 'transaction',
    '/ui/PAYMENTS/addTransferTemplate/ONE2MANY': 'transaction',
    '/ui/PAYMENTS/addTransferTemplate/MANY2ONE': 'transaction',
    '/ui/logout': 'other',
    '/ui/PAYMENTS/addTransfer/MANY2ONE': 'transaction',
    '/ui/PMTS/import/payment/transfer/TRANSFERS_LIST': 'transaction',
    '/ui/PAYMENTS/addSingleTransferByTemplate/4817': 'transaction',
    '/ui/PAYMENTS/quickEntry': 'transaction',
    '/ui/PAYMENTS/insert/stopPayment/PAY_CM_IV_LIST': 'transaction',
    '/ui/PAYMENTS/view/stopPayment/PAY_CM_IV_LIST': 'transaction',
    '/ui/CM/multiAdd/STOP': 'transaction',
    '/ui/RISK/import/cm/importStopsListView/importResultsStopPaymentImport/CM_IMPORTRESULTS': 'transaction',


    '/ui/ADMINSTRATION/alerts': 'change_details',
    '/ui/ADMINISTRATION/addUser': 'change_details',
    '/ui/ADMINISTRATION/viewUser': 'change_details',
    '/ui/ADMINISTRATION/addUsers': 'change_details',
    '/ui/ADMINISTRATION/modifyUser': 'change_details',
    '/ui/ADMINSTRATION/alertsCenter/mySettings': 'change_details',
};
