export default {
    // Blues
    'curiousBlue':                                 '#39c',
    'steelBlue':                                   '#518cc4',
    'firefly':                                     '#0a1822',
    'hawkesBlue':                                  '#c8dffc',

    'marinique':                                   '#2e2952',
    'ceruleanBlue':                                '#2459a8',
    'blizzardBlue':                                '#aed8ef',
    'cornflower':                                  '#90C6E6',

    // Greens
    'forestGreen':                                 '#1a8927',
    'everglade':                                   '#295d35',

    // Oranges
    'jaffa':                                       '#f47f3a',

    // Reds
    'persianRed':                                  '#cc3333',
    'apricotPeach':                                '#fbd3bc',

    // Browns
    'dune':                                        '#2e2925',

    // Grays
    'emperor':                                     '#555555',
    'athensGray':                                  '#e9ecef',
    'athensGrayTwo':                               '#f8f9fa',
    'abbey':                                       '#464649',
    'shark':                                       '#212122',
    'geyser':                                      '#dae0e5',
    'midGray':                                     '#6B6B70',
    'frenchGray':                                  '#cfcfd1',
    'mercury':                                     '#e1e1e1',
    'white':                                       '#ffffff'
};