import palette from '../palette';

export default {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',

    primary: {
        backgroundColor: palette.forestGreen,
        borderColor: palette.forestGreen,
        focusColor: palette.everglade,
        hoverColor: palette.everglade,
        textColor: palette.white,
        disabledBackground: palette.geyser,
        disabledBorder: palette.geyser,
        disabledColor: palette.white,
        '&:hover': {
            borderColor: palette.everglade,
            color: palette.white
        }
    },

    secondary: {
        backgroundColor: palette.white,
        borderColor: palette.everglade,
        focusColor: palette.everglade,
        hoverColor: palette.everglade,
        textColor: palette.forestGreen,
        disabledBackground: palette.white,
        disabledColor: palette.geyser,
        '&:hover': {
            color: palette.white
        },
        '&:focus': {
            color: palette.white
        },
    },

    tertiary: {
        textColor: palette.ceruleanBlue,
        '&:hover': {
            color: palette.ceruleanBlue,
        },
        '&.disabled': {
            color: palette.cornflower
        }
    },

    extraSmall: {
        borderRadius: 0
    },

    normal: {
        borderRadius: 0
    }
};