import palette from '../palette';

export default {
    colorbar: {
        background: palette.blizzardBlue,
        height: 5
    },

    pageHeaderTitle: {
        color: palette.white
    }, 

    headerTitleDark: {
        background: palette.ceruleanBlue,
        color: palette.white
    },

    headerTotalsDark: {
        background: palette.ceruleanBlue,
        color: palette.white
    },

    backNavBackground: {
        background: palette.ceruleanBlue,
    },

    backNavBackgroundDark: {
        background: palette.ceruleanBlue,
    },

    backNavIconColorDark: palette.white,

    summaryFooter: {
        backgroundColor: palette.ceruleanBlue,
        color: palette.white
    },

    footerButtonPrimary: {
        background: palette.forestGreen,
        borderColor: palette.forestGreen,
        borderRadius: 0,
        color: palette.white,
        '&:hover, &:focus': {
            background: palette.everglade,
            borderColor: palette.everglade,
            color: palette.white
        },
        '&.disabled': {
            color: palette.white,
            background: palette.geyser
        }
    },

    footerButtonSecondary: {
        background: palette.blizzardBlue,
        borderColor: palette.blizzardBlue,
        color: palette.ceruleanBlue,
        '&:hover, &:focus': {
            background: palette.ceruleanBlue,
            borderColor: palette.ceruleanBlue,
            color: palette.white
        },
        '&.disabled': {
            color: palette.white,
            background: palette.geyser
        }
    }
};