import buttons from './buttons';
import dbiqeStopPayments from './dbiqeStopPayments';
import icons from './icons';
import modal from './modal';

export default {
    buttons,
    dbiqeStopPayments,
    icons,
    modal
};
